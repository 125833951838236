import { useAnimations, useGLTF } from '@react-three/drei';
import { useEffect, useRef } from 'react';
import * as THREE from 'three';
import useGlobal from '../stores/useGlobal.jsx';
import { updateShadowsRecursively } from '../utils/meshes.js';

const PLAY_SCALE = 2;

export default function Confetti() {
  const triggerAnimate = useGlobal((state) => state.confetti);
  const confetti = useGLTF('./CONFETTI.glb');
  const confettiRef = useRef();

  const { actions, mixer } = useAnimations(confetti.animations, confetti.scene);

  useEffect(() => {
    updateShadowsRecursively(confettiRef.current);
  }, [confettiRef.current]);

  // when the animation ends, we gotta hide that confetti baby
  const handleAnimationEnd = (e) => {
    // hide the confetti after animation because of unnecessary cpu usage
    confettiRef.current.visible = false;
    // remove the listener to prevent memory leak
    mixer.removeEventListener('finished', handleAnimationEnd);
  };

  useEffect(() => {
    if (triggerAnimate) {
      confettiRef.current.visible = true;

      const clips = Object.values(actions);
      clips.forEach((action) => {
        // play the animation ONCE
        action.setLoop(THREE.LoopOnce, 1);

        // change the speed of animation
        // action.timeScale = 1.5;

        action.reset().play();
      });

      mixer.addEventListener('finished', handleAnimationEnd);
    }
  }, [triggerAnimate, actions]);

  return (
    <primitive
      object={confetti.scene}
      scale={PLAY_SCALE}
      position={[-15, 6.2, -15]}
      ref={confettiRef}
      visible={false}
    />
  );
}
