import {
  Text,
  Text3D,
  useAnimations,
  useGLTF,
  useMatcapTexture,
} from '@react-three/drei';
import { RigidBody } from '@react-three/rapier';
import { useEffect, useRef, useState } from 'react';
import { updateShadowsRecursively } from '../utils/meshes';

const ISLAND_SCALE = 0.3;

export default function Island() {
  const model = useGLTF('./smartcar.glb');
  const islandRef = useRef();
  const islandRigid = useRef();
  const [islandSize, setIslandSize] = useState('normal');
  const [selected, setSelected] = useState(false);

  const animations = useAnimations(model.animations, model.scene);

  useEffect(() => {
    updateShadowsRecursively(islandRef.current);
  }, [islandRef.current]);

  useEffect(() => {
    for (const [key, value] of Object.entries(animations.actions)) {
      value.play();
    }
  }, []);

  const title = 'SMARTCAR\n(2022-PRESENT)';

  // const [matcapTexture] = useMatcapTexture('65A0C7_C3E4F8_A7D5EF_97CAE9', 256);
  const [matcapTexture] = useMatcapTexture('496DBA_94C9F2_72A7E2_84B4EC', 256);

  return (
    <>
      <RigidBody type='fixed' colliders='trimesh' ref={islandRigid} castShadow>
        <primitive
          ref={islandRef}
          object={model.scene}
          scale={ISLAND_SCALE}
          position-y={-0.35}
          position-x={30}
          rotation-y={Math.PI * 0.6}
          rotation-z={-Math.PI * 0.006}
        />
      </RigidBody>
      <Text3D
        font='./ibm_plex_sans_bold.json'
        size={1.5}
        lineHeight={0.7}
        letterSpacing={-0.06}
        anchorX='left'
        anchorY='middle'
        bevelEnabled
        bevelSize={0.04}
        bevelThickness={0.1}
        curveSegments={32}
        position={[30, 8, -6]}
        rotation-x={-Math.PI * 0.1}
        // castShadow
      >
        {title}
        <meshMatcapMaterial matcap={matcapTexture} />
      </Text3D>
      {/* New lines: https://stackoverflow.com/questions/8627902/how-to-add-a-new-line-in-textarea-element */}
      <Text
        font='./ibm_plex_sans_bold.ttf'
        fontSize={1.4}
        color='white'
        anchorX='left'
        anchorY='middle'
        position={[30, 4, -4]}
        rotation-x={-Math.PI * 0.1}
      >
        Software Engineer&#13;&#10;React, Node.js, TypeScript, JavaScript
      </Text>
    </>
  );
}
