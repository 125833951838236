import {
  PerspectiveCamera,
  useGLTF,
  useHelper,
  useKeyboardControls,
} from '@react-three/drei';
import { useFrame, useThree } from '@react-three/fiber';
import { CuboidCollider, RigidBody } from '@react-three/rapier';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { updateShadowsRecursively } from '../utils/meshes';
import * as THREE from 'three';

const CAMERA_OFFSET_Y = 20;
const CAMERA_OFFSET_Z = 30;
const CAMERA_TARGET_OFFSET_Y = 0.25;
const SPEED_LIMIT = 90;

export default function Boat() {
  const [subscribeKeys, getKeys] = useKeyboardControls();
  const { mouse } = useThree();
  const model = useGLTF('./BOAT2.glb');
  const boat = useRef();
  const boatRigid = useRef();
  const water = useRef();
  const underWater = useRef();
  const shadowCameraRef = useRef();
  const lightRef = useRef();
  // useHelper(lightRef, THREE.DirectionalLightHelper, 155, 'red');

  const [moveBoat, setMoveBoat] = useState(false);
  const [boatDirection, setBoatDirection] = useState(null);

  // Smooths the camera movement
  const [smoothedCameraPosition] = useState(() => new THREE.Vector3());
  const [smoothedCameraTarget] = useState(() => new THREE.Vector3());

  useEffect(() => {
    // Sets the light on the camera
    lightRef.current.target = boat.current;
    updateShadowsRecursively(boat.current);
  }, []);

  useEffect(() => {
    if (moveBoat && boatDirection) {
      const boatPosition = boatRigid.current.translation();
      const boatRotation = boatRigid.current.rotation();

      var direction = new THREE.Vector3();
      direction.subVectors(boatPosition, boatDirection);
      const dotProduct = direction.z;
      const magDirection = Math.sqrt(direction.x ** 2 + direction.z ** 2);

      let angle = Math.acos(dotProduct / magDirection);
      if (direction.x < 0) {
        angle *= -1;
      }

      const rotation = new THREE.Quaternion();
      rotation.setFromEuler(new THREE.Euler(0, angle, 0, 'XYZ'));

      boatRigid.current.setRotation(rotation);
    }
  }, [boatDirection, moveBoat]);

  useFrame(({ camera }, delta) => {
    const { boost } = getKeys();
    if (moveBoat) {
      // Set boat speed according to mouse position
      let speedX = mouse.x;
      let speedZ = mouse.y;

      const normalized = Math.sqrt(speedX ** 2 + speedZ ** 2);
      const factor = (delta * SPEED_LIMIT) / normalized;

      speedX *= factor;
      speedZ *= factor;

      if (boost) {
        speedX *= 2;
        speedZ *= 2;
      }

      // Apply the speed to the boat
      boatRigid.current.applyImpulse({
        x: speedX,
        y: 0,
        z: -speedZ,
      });
    }
    // Keeps the camera on the boat
    const cameraPosition = new THREE.Vector3();
    cameraPosition.copy(boatRigid.current.translation());
    cameraPosition.y += CAMERA_OFFSET_Y;
    cameraPosition.z += CAMERA_OFFSET_Z;

    const cameraTarget = new THREE.Vector3();
    cameraTarget.copy(boatRigid.current.translation());
    cameraTarget.y += CAMERA_TARGET_OFFSET_Y;

    // Smooths the camera movement
    smoothedCameraPosition.lerp(cameraPosition, 10 * delta);
    smoothedCameraTarget.lerp(cameraTarget, 10 * delta);

    camera.position.copy(smoothedCameraPosition);
    camera.lookAt(smoothedCameraTarget);

    // Keeps the light on the camera
    const lightPosition = new THREE.Vector3();
    lightPosition.copy(camera.position);
    lightPosition.addScalar(20);
    lightRef.current.position.copy(lightPosition);
  });

  const [clickCount, setClickCount] = useState(0);
  const timerRef = useRef(null);
  // When someone is clicking on the plane, the boat will move towards that direction
  const handlePointerDown = useCallback(
    (event) => {
      setClickCount((prev) => prev + 1);

      if (timerRef.current !== null) {
        clearTimeout(timerRef.current);
      }
      if (clickCount >= 1) {
        // Apply the upwards force to the boat
        boatRigid.current.applyImpulse({
          x: 0,
          y: 10,
          z: 0,
        });
      }

      timerRef.current = setTimeout(() => {
        setClickCount(0);
      }, 250); // 250ms delay for double-click detection
      const boatPosition = boatRigid.current.translation();
      const boatDirectional = event.point;
      boatDirectional.y = boatPosition.y;
      setBoatDirection(boatDirectional);
      setMoveBoat(true);
    },
    [clickCount]
  );

  // When someone is not clicking on the plane, the boat will stop moving
  const handlePointerUp = useCallback((event) => {
    setMoveBoat(false);
  }, []);

  // When someone is moving the mouse on the plane, set the boat direction to the mouse position
  const handlePointerMove = useCallback(
    (event) => {
      if (moveBoat) {
        const boatPosition = boatRigid.current.translation();

        const boatDirectional = event.point;
        boatDirectional.y = boatPosition.y;
        setBoatDirection(boatDirectional);
      }
    },
    [moveBoat, mouse]
  );

  return (
    <>
      {/* boat */}
      <RigidBody
        ref={boatRigid}
        rotation-y={Math.PI}
        colliders={'cuboid'}
        linearDamping={0.5}
        // prevents the boat from rotating muy rapido y loco
        angularDamping={100}
        canSleep={false}
        castShadow
      >
        <primitive object={model.scene} scale={0.25} ref={boat} castShadow />
      </RigidBody>

      {/* ball */}
      <RigidBody
        colliders='ball'
        friction={0.5}
        restitution={0.6}
        linearDamping={0.5}
        angularDamping={0.9}
      >
        <mesh position-x={10} position-y={3} castShadow>
          <sphereGeometry args={[0.5]} castShadow />
          <meshStandardMaterial color={'red'} />
        </mesh>
      </RigidBody>

      {/* invisible plane used for boat controls */}
      <mesh
        name={'plane'}
        rotation-x={-0.5 * Math.PI}
        // position-y={1}
        onPointerDown={handlePointerDown}
        onPointerUp={handlePointerUp}
        onPointerMove={handlePointerMove}
        receiveShadow
      >
        <planeGeometry args={[500, 500, 1, 1]} />
        <meshStandardMaterial color={'blue'} visible={false} />
      </mesh>

      {/* water looks */}
      <mesh
        receiveShadow
        position-y={0}
        rotation-x={-Math.PI * 0.5}
        ref={water}
      >
        <planeGeometry args={[500, 500, 1, 1]} />
        <meshStandardMaterial color='#66b9ba' />
      </mesh>
      {/* actual water that is interacting with boat */}
      <CuboidCollider
        args={[500, 0.1, 500]}
        position={[0, -0.15, -2]}
        restitution={0.2}
        friction={0.8}
        ref={underWater}
      />

      <directionalLight
        ref={lightRef}
        castShadow
        position={[20, 20, 20]}
        intensity={1.1}
        shadow-camera-fov={45}
        shadow-camera-near={50}
        shadow-camera-far={200}
        shadow-camera-left={-50}
        shadow-camera-right={50}
        shadow-camera-top={50}
        shadow-camera-bottom={-15}
        shadow-normalBias={0.04}
        shadow-mapSize={[4098, 4098]}
      >
        <perspectiveCamera ref={shadowCameraRef} />
      </directionalLight>
    </>
  );
}
