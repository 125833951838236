import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';

export default create(
  subscribeWithSelector((set) => {
    return {
      confetti: 0,

      start: () => {
        set((state) => {
          return { confetti: (state.confetti += 1) };
        });
      },
    };
  })
);
