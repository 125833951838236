import { Perf } from 'r3f-perf';
import Boat from './components/Boat';
import Island from './components/SmartcarIsland';
import Play from './components/Play';
import Socials from './components/Socials';
import Confetti from './components/Confetti';
import { Physics } from '@react-three/rapier';

export default function Experience() {
  return (
    <>
      {/* <Perf position='top-left' /> */}
      {/* <axesHelper args={[100]}></axesHelper> */}
      <ambientLight intensity={0.4} />

      <Physics timeStep={1 / 60} gravity={[0, -11, 0]}>
        <Boat />
        <Island />
        <Play />
        <Socials />
        <Confetti />
      </Physics>
    </>
  );
}
